<template>
  <div class="index"></div>
</template>

<script>
import { routesModuleData, routesConvert } from "@/router/index";

export default {
  data() {
    return {};
  },
  created() {
    this.$router.replace({
      name: routesConvert(routesModuleData.manage, true).name,
    });
  },
};
</script>
<style lang="scss" scoped></style>
